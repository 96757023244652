<mat-dialog-content>
  <div class="d-flex justify-content-center align-items-center">

    <img #imgShowed *ngIf="isImage && selectedDoc" [src]="selectedDoc" alt="doc" class="viewer">

    <div *ngIf="!selectedDoc">
      <div class="d-flex flex-row justify-content-center align-items-center py-5">
        <label>Chargement en cours...</label>
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions align="center">
  <button (click)="close()" class="btn btn-primary m-4">Fermer</button>
</div>

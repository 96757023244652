import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment, globalAppParam} from 'src/environments/environment';
import {Year} from 'src/app/model/user/year.model';
import {MedicalQuestionnaire} from 'src/app/model/user/medical-questionnaire.model';
import {Observable} from 'rxjs';
import {FileInfoDTO} from 'src/app/model/dto/file-info.dto';
import {AttestationDTO} from '../../model/dto/attestation.dto';
import {isNotNullOrUndefined} from '../../utils/utils.static';

@Injectable({
  providedIn: 'root'
})
export class YearWebservice {
  private readonly root = `${environment.envApiUrl}/years`;

  constructor(private readonly http: HttpClient) {}

  postUpdateCertificat(idUser: number, formData: FormData): Observable<FileInfoDTO[]> {
    return this.http.post<FileInfoDTO[]>(`${this.root}/certif/${idUser}`, formData);
  }

  delRemoveCetificat(idYear: number) {
    return this.http.delete(`${this.root}/certif/${idYear}`);
  }

  postUpdateYearSport(year: Year) {
    return this.http.post(`${this.root}/update-sport`, year);
  }

  addMedicalQuestionnaire(medicalQuestionnaire: MedicalQuestionnaire) {
    return this.http.post(`${this.root}/add/questionnaire`, medicalQuestionnaire);
  }

  getAllSdlvDocsByUserId(idUser: number) {
    return this.http.get(`${this.root}/docs/sdlv/${idUser}`);
  }

  getUserDoc(idUser: number, docName: string): Observable<FileInfoDTO> {
    return this.http.get<FileInfoDTO>(`${this.root}/doc/${idUser}?fileName=${docName}`);
  }

  postValidationYearSport(idYoung: number) {
    return this.http.post(`${this.root}/validationSport`, idYoung);
  }

  postValidationYearJob(idYoung: number) {
    return this.http.post(`${this.root}/validationJob`, idYoung);
  }

  postCancelYear(idYoung: number) {
    return this.http.post(`${this.root}/cancel-validation`, idYoung);
  }

  getCurrentYear(): number {
    let limitYear: number = new Date().getFullYear();
    if (new Date() < globalAppParam.limitDateInscription) {
      limitYear--;
    }

    return limitYear;
  }

  updateQuestionnaire(id: number, isMedicalValide: boolean, attestationDTO: AttestationDTO = null) {
    if (isNotNullOrUndefined(attestationDTO)) {
      return this.http.put(
        `${this.root}/updateQuestionnaire/${id}/${isMedicalValide}`,
        attestationDTO
      );
    } else {
      return this.http.put(`${this.root}/updateQuestionnaire/${id}/${isMedicalValide}`, {});
    }
  }

  checkLastCertificate(id: number) {
    return this.http.get(`${this.root}/checkLastCertificat/${id}`);
  }

  acceptEngagementChart(idYear: number) {
    return this.http.post(`${this.root}/engagementChart`, idYear);
  }
}

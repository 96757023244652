import {Component, OnDestroy} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-default',
  templateUrl: './dialog-default.component.html',
  styleUrls: ['./dialog-default.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogTitle, MatDialogContent, MatDialogActions]
})
export class DialogDefaultComponent implements OnDestroy {
  result: boolean;
  title: string;
  message: string;
  no: string;
  yes: string;

  constructor(public dialogRef: MatDialogRef<DialogDefaultComponent>) {}

  accept() {
    this.result = true;
    this.dialogRef.close(this.result);
  }

  decline() {
    this.result = false;
    this.dialogRef.close(this.result);
  }

  ngOnDestroy() {
    if (this.result === undefined) {
      this.dialogRef.close(false);
    }
  }
}

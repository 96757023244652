import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {YearWebservice} from '../../../../services/webservices/year.webservice';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-show-docs',
  templateUrl: './dialog-show-docs.component.html',
  styleUrls: ['./dialog-show-docs.component.scss'],
  standalone: true,
  imports: [MatDialogContent, NgIf, MatProgressSpinnerModule, MatDialogActions]
})
export class DialogShowDocsComponent extends Destroyed implements OnInit, OnDestroy {
  @ViewChild('imgShowed') imgShowed;

  idUser: number;
  doc: string;

  selectedDoc: SafeUrl;
  arrayBuffer: Uint8Array;
  blob: Blob;
  isImage: boolean;
  imageFileExtensions: string[] = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];

  fileInfoDto;
  file;

  constructor(
    public dialogRef: MatDialogRef<DialogShowDocsComponent>,
    private readonly sanitizer: DomSanitizer,
    private readonly yearWS: YearWebservice
  ) {
    super();
  }

  ngOnInit() {
    if (this.doc !== undefined) {
      this.yearWS
        .getUserDoc(this.idUser, this.doc)
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          if (value) {
            if (
              value.file &&
              this.imageFileExtensions.some(
                (t) => t === `.${value.fileType.replace('.', '').toLowerCase()}`
              )
            ) {
              const objectURL =
                'data:image/' + value.fileType.replace('.', '') + ';base64,' + value.file;
              this.selectedDoc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
              this.isImage = true;
            } else {
              const bytesArray = this.base64ToByteArray(value.fileContent);
              const blob = new Blob([bytesArray], {type: 'application/pdf'});
              const fileObjectURL = URL.createObjectURL(blob);
              window.open(fileObjectURL);

              this.close();
            }
          }
        });
    } else if (this.fileInfoDto !== undefined) {
      if (
        this.imageFileExtensions.some(
          (t) => t === `.${this.fileInfoDto.fileType.split('/')[1].replace('.', '').toLowerCase()}`
        )
      ) {
        const objectURL =
          'data:image/' +
          this.fileInfoDto.fileType.replace('.', '') +
          ';base64,' +
          this.fileInfoDto.file;
        this.selectedDoc = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        this.isImage = true;
      } else {
        const bytesArray = this.base64ToByteArray(this.fileInfoDto.fileContent);
        const blob = new Blob([bytesArray], {type: 'application/pdf'});
        const fileObjectURL = URL.createObjectURL(blob);
        window.open(fileObjectURL);

        this.close();
      }
    } else if (this.file !== undefined) {
      const objectURL = URL.createObjectURL(this.file);

      window.open(objectURL);
      this.close();
    }
  }

  base64ToByteArray(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.dialogRef.close();
  }
}
